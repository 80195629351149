import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { db } from '../../firebase.config';

const SuperAdminCodes = () => {
  const [codes, setCodes] = useState([]);

  useEffect(() => {
    const CODES = [];
    const ref = collection(db, 'codes');

    onSnapshot(ref, (snap) => {
      snap.docs.forEach((doc) => {
        CODES.push({ ...doc.data(), _id: doc.id });
      });

      setCodes(CODES);
    });
  }, []);

  const generateNewCode = async () => {
    const collectionRef = collection(db, 'codes');

    const randomNumber = Math.floor(Math.random() * 999999 + 9999);

    const newCode = {
      code: randomNumber.toString(),
      date: new Date(),
      used: false,
    };

    try {
      toast.loading('Generating new code...');

      await addDoc(collectionRef, newCode);

      toast.dismiss();

      toast.success('Code generated.');

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error('Error generating code');
    }
  };

  return (
    <div className='padding py-32'>
      <div className='min-h-screen mt-32'>
        <div mt={8} sx={{ color: '#f5f5f5' }}>
          <p className='text-xl font-bold mb-4'>Withdrawal Codes</p>

          <div className='my-4 mb-8'>
            <button
              className='p-4 px-16 bg-primary text-center rounded-md text-sm text-white'
              onClick={generateNewCode}
            >
              Generate new code
            </button>
          </div>

          {/*  */}
          {codes && codes.length > 0 ? (
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
              {[...codes]
                .sort((a, b) => a.used - b.used)
                .map((code) => (
                  <div
                    key={code.code}
                    className='p-4 bg-white text-gray-700 rounded-xl max-w-sm border border-gray-200'
                  >
                    <span
                      className={`inline-block mb-4 text-[12px] p-2 px-4 rounded-full ${
                        code.used
                          ? 'text-red-200 bg-red-800'
                          : 'text-green-200 bg-green-900'
                      }`}
                    >
                      {code.used ? 'Used' : 'Not Used'}
                    </span>
                    <p className='text-lg font-light'>{code.code}</p>

                    <button
                      className={`bg-gray-400 text-white mt-2 text-sm p-2 px-6 rounded-md`}
                      onClick={() => {
                        window.navigator.clipboard
                          .writeText(code.code)
                          .then(() => toast.success('Copied!'));
                      }}
                    >
                      Copy
                    </button>
                  </div>
                ))}
            </div>
          ) : (
            <div className='bg-white p-8 rounded-xl shadow-md'>
              <p className='text-2xl text-gray-800'>No withdrawal codes</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminCodes;
