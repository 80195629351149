import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SuperAdminLayout from './layouts/AdminLayout';
import ProtectedRoute from './layouts/PrivateRoute';
import About from './pages/About';
import Contact from './pages/Contact';
import FAQs from './pages/FAQs';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import DashboardDeposit from './pages/dashboard/Deposit';
import DashboardHome from './pages/dashboard/Home';
import DashboardLoan from './pages/dashboard/Loan';
import DashboardSettings from './pages/dashboard/Settings';
import DashboardTransactions from './pages/dashboard/Transactions';
import DashboardWithdraw from './pages/dashboard/Withdraw';
import SuperAdminCodes from './pages/super/Codes';
import SuperAdminHome from './pages/super/Home';
import SuperAdminLogin from './pages/super/Login';
import AdminUserDetails from './pages/super/Users/UserDetails';
import SuperAdminUsers from './pages/super/Users/index';

const App = () => {
  return (
    <>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/FAQs' element={<FAQs />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Signup />} />
          <Route path='/dashboard' element={<ProtectedRoute />}>
            <Route path='/dashboard' element={<DashboardHome />} />
            <Route path='/dashboard/deposit' element={<DashboardDeposit />} />
            <Route path='/dashboard/withdraw' element={<DashboardWithdraw />} />
            <Route path='/dashboard/loan' element={<DashboardLoan />} />
            <Route
              path='/dashboard/transactions'
              element={<DashboardTransactions />}
            />
            <Route path='/dashboard/settings' element={<DashboardSettings />} />
          </Route>
          <Route path='/super-admin/login' element={<SuperAdminLogin />} />
          <Route path='/super-admin' element={<SuperAdminLayout />}>
            <Route path='/super-admin' element={<SuperAdminHome />} />
            <Route path='/super-admin/codes' element={<SuperAdminCodes />} />
            <Route path='/super-admin/users' element={<SuperAdminUsers />} />
            <Route
              path='/super-admin/users/:id'
              element={<AdminUserDetails />}
            />
          </Route>
          <Route path='*' element={<Home />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
