// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyA08ITMLGRSAcv1ZCjtDnYxlUdEsryn-2I',
  authDomain: 'meta-finance-cf455.firebaseapp.com',
  projectId: 'meta-finance-cf455',
  storageBucket: 'meta-finance-cf455.appspot.com',
  messagingSenderId: '795306446938',
  appId: '1:795306446938:web:b7b92d98d6305185c58863',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage();
